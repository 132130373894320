import React, { useState } from 'react'
import AuthHeader from 'components/Headers/AuthHeader.jsx'
import {
  Button,
  Card,
  CardBody,
  FormGroup,
  Form,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Container,
  Row,
  Col,
} from 'reactstrap'
import classnames from 'classnames'
import { Link, Redirect } from 'react-router-dom'
import { forgotPassword } from '../../../data/password'
import Swal from 'sweetalert2'

const ForgotPassword = (props) => {
  const [email, setEmail] = useState('')
  const [redirect, setRedirect] = useState(false)
  const [focusedEmail, setFocusedEmail] = useState(false)

  const handleChange = (e) => {
    setEmail(e.target.value)
  }

  const handleSubmit = (e) => {
    e.preventDefault()
    const payload = {
      email: email,
    }
    forgotPassword(payload).then((res) => {
      if (res && res.status === 200) {
        Swal.fire({
          icon: 'success',
          title: 'Email sent',
          text: 'Please check your email to reset your password',
          confirmButtonText: 'Return to Login',
          onAfterClose: () => setRedirect(true),
        })
      } else {
        Swal.fire({
          icon: 'error',
          text: 'Please make sure you have provided the correct email address',
        })
      }
    })
  }

  const enableButton = email.length > 0

  if (redirect) {
    return <Redirect to='/login' />
  }

  return (
    <>
      <AuthHeader title='Project Liftoff' lead='Welcome!' />
      <Container className='mt--8 pb-5'>
        <Row className='justify-content-center'>
          <Col lg='5' md='7'>
            <Card className='bg-secondary border-0 mb-0'>
              <CardBody className='px-lg-5 py-lg-5'>
                <div className='text-center text-muted mb-4'>
                  <small>Forgot Password</small>
                </div>
                <Form role='form'>
                  <FormGroup
                    className={classnames('mb-3', {
                      focused: focusedEmail,
                    })}
                  >
                    <InputGroup className='input-group-merge input-group-alternative'>
                      <InputGroupAddon addonType='prepend'>
                        <InputGroupText>
                          <i className='ni ni-email-83' />
                        </InputGroupText>
                      </InputGroupAddon>
                      <Input
                        placeholder='Email'
                        type='email'
                        name='email'
                        value={email}
                        onChange={handleChange}
                        onFocus={() => setFocusedEmail(true)}
                        onBlur={() => setFocusedEmail(false)}
                      />
                    </InputGroup>
                  </FormGroup>
                  <div className='text-center'>
                    <Button
                      className='my-4'
                      color={enableButton ? 'success' : 'default'}
                      type='submit'
                      onClick={
                        enableButton ? handleSubmit : (e) => e.preventDefault()
                      }
                    >
                      Send Reset Email
                    </Button>
                  </div>
                </Form>
              </CardBody>
            </Card>
            <Row className='mt-3'>
              <Col xs='6'>
                <Link className='text-light' to='/login'>
                  <small>Return to Login</small>
                </Link>
              </Col>
              <Col className='text-right' xs='6'>
                <a
                  className='text-light'
                  href='#pablo'
                  onClick={(e) => e.preventDefault()}
                >
                  <small>Create new account</small>
                </a>
              </Col>
            </Row>
          </Col>
        </Row>
      </Container>
    </>
  )
}

export default ForgotPassword
