import Api from './laravelConfig'

const getTodos = (projectId) => {
  return Api.axiosInstance()
    .get(`/projects/${projectId}/todos`)
    .then((res) => {
      if (res && res.data) {
        return res.data.data
      }
    })
    .catch((error) => {
      if (error.response) {
        return error.response
      }
    })
}

const getTodo = (todoId) => {
  return Api.axiosInstance()
    .get(`/todos/${todoId}`)
    .then((res) => {
      if (res && res.data) {
        return res.data.data
      }
    })
    .catch((error) => {
      if (error.response) {
        return error.response
      }
    })
}

const markComplete = (todoId) => {
  return Api.axiosInstance()
    .post(`/todos/${todoId}/status`, { status: 'complete' })
    .then((res) => {
      if (res && res.data) {
        return res.data.data
      }
    })
    .catch((error) => {
      if (error.response) {
        return error.response
      }
    })
}

const submitComment = (todoId, payload) => {
  return Api.axiosInstance()
    .post(`/todos/${todoId}/comment`, payload)
    .then((res) => {
      if (res && res.data) {
        return res.data
      }
    })
    .catch((error) => {
      if (error.response) {
        return error.response
      }
    })
}

const uploadFiles = (todoId, payload, setUploadPercent) => {
  return Api.axiosFileUpload(setUploadPercent)
    .post(`/todos/${todoId}/attachment`, payload)
    .then((res) => {
      if (res && res.data) {
        return res.data
      }
    })
    .catch((error) => {
      if (error.response) {
        return error.response
      }
    })
}

export { getTodos, getTodo, markComplete, submitComment, uploadFiles }
