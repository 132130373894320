import React, { useContext } from 'react'
import {
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown,
  DropdownToggle,
  Media,
  Nav,
} from 'reactstrap'
import UserContext from '../Context/UserContext'

const UserDropdown = (props) => {
  const { userName } = useContext(UserContext)

  const handleLogOut = (e) => {
    e.preventDefault()
    sessionStorage.clear()
    props.history.push('/login')
  }

  const initialsCreator = () => {
    const nameArray = userName ? userName.split(' ') : ''
    let initials = ''
    for (let i = 0; i < nameArray.length; i++) {
      initials += nameArray[i].charAt(0)
    }
    return initials
  }

  return (
    <Nav className='align-items-center ml-auto ml-md-0' navbar>
      <UncontrolledDropdown nav>
        <DropdownToggle
          className='nav-link pr-0'
          color=''
          tag='a'
          style={{ cursor: 'pointer' }}
        >
          <Media className='align-items-center'>
            <span className='avatar avatar-sm rounded-circle'>
              <small style={{ textTransform: 'uppercase', fontSize: '1rem' }}>
                {initialsCreator()}
              </small>
            </span>
            <Media className='ml-2 d-none d-lg-block'>
              <span className='mb-0 text-sm font-weight-bold'>{userName}</span>
            </Media>
          </Media>
        </DropdownToggle>
        <DropdownMenu right>
          <DropdownItem className='noti-title' header tag='div'>
            <h6 className='text-overflow m-0'>Welcome!</h6>
          </DropdownItem>
          <DropdownItem
            href='#pablo'
            onClick={(e) => {
              e.preventDefault()
              props.history.push('/user')
            }}
          >
            <i className='ni ni-single-02' />
            <span>My profile</span>
          </DropdownItem>
          <DropdownItem divider />
          <DropdownItem href='#pablo' onClick={(e) => handleLogOut(e)}>
            <i className='ni ni-user-run' />
            <span>Logout</span>
          </DropdownItem>
        </DropdownMenu>
      </UncontrolledDropdown>
    </Nav>
  )
}

export default UserDropdown
