/* eslint-disable */

import axios from 'axios'

const Api = {
  axiosInitialize() {
    const instance = axios.create({
      baseURL: process.env.REACT_APP_LARAVEL_BASE_URL,
      headers: {
        Accept: 'application/json',
      },
    })
    return instance
  },
  axiosInstance() {
    const instance = axios.create({
      baseURL: process.env.REACT_APP_LARAVEL_BASE_URL,
      timeout: 20000,
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${sessionStorage.getItem('access_token')}`,
      },
    })
    return instance
  },
  axiosFileUpload(setUploadPercent) {
    const instance = axios.create({
      baseURL: process.env.REACT_APP_LARAVEL_BASE_URL,
      headers: {
        'Content-Type': 'multipart/form-data',
        Authorization: `Bearer ${sessionStorage.getItem('access_token')}`,
      },
      onUploadProgress: (progressEvent) => {
        setUploadPercent(
          parseInt(
            Math.round((progressEvent.loaded * 100) / progressEvent.total)
          )
        )

        // Clear percentage
        setTimeout(() => setUploadPercent(0), 2500)
      },
    })
    return instance
  },
}

export default Api
