/*!

=========================================================
* Argon Dashboard PRO React - v1.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-pro-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from 'react'
// nodejs library to set properties for components
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'
// reactstrap components
import { Breadcrumb, BreadcrumbItem, Container, Row, Col } from 'reactstrap'
import { locationSwitch } from '../../utils/breadcrumbSwitches'

const SimpleHeader = (props) => {
  const sessionProjects = JSON.parse(sessionStorage.getItem('projects'))
  const pathName = props.location.pathname
  const locationArray = pathName.split('/')
  const selectedProject =
    sessionProjects &&
    locationArray[2] &&
    sessionProjects.find(
      (project) => project.id === JSON.parse(locationArray[2])
    )
  const selectedProjectName = selectedProject ? selectedProject.name : ''

  return (
    <div className='header header-dark bg-transparent content__title content__title--calendar'>
      <Container fluid>
        <div className='header-body'>
          <Row className='align-items-center py-4'>
            <Col lg='6' xs='7'>
              <Breadcrumb
                className='d-none d-md-inline-block'
                listClassName='breadcrumb-links breadcrumb-dark'
              >
                <div style={{ display: 'flex', alignItems: 'center' }}>
                  <BreadcrumbItem>
                    <Link to={`/projects/${locationArray[2]}/dashboard`}>
                      <h6 className='fullcalendar-title h2 text-white mb-0'>
                        {selectedProjectName}
                      </h6>
                    </Link>
                  </BreadcrumbItem>
                  {locationArray[3] && (
                    <BreadcrumbItem aria-current='page' className='active'>
                      <Link
                        to={`/projects/${locationArray[2]}/${locationArray[3]}`}
                      >
                        {locationSwitch(locationArray[3])}
                      </Link>
                    </BreadcrumbItem>
                  )}
                </div>
              </Breadcrumb>
            </Col>
          </Row>
        </div>
      </Container>
    </div>
  )
}

SimpleHeader.propTypes = {
  name: PropTypes.string,
  parentName: PropTypes.string,
}

export default SimpleHeader
