/*!

=========================================================
* Argon Dashboard PRO React - v1.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-pro-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React, { useState, useEffect, useRef } from 'react'

// reactstrap components
import {
  Card,
  CardHeader,
  CardBody,
  Container,
  Row,
  Col,
  Button,
  FormGroup,
  Input,
} from 'reactstrap'
import parse from 'html-react-parser'

import {
  momentCountdown,
  isLate,
  momentDateCreator,
} from '../utils/momentFunctions'
import { TodoHistory } from '../components/Todos/TodoHistory.jsx'
import TodoDropZone from '../components/Todos/TodoDropZone'
import { getTodo, markComplete, submitComment } from '../data/todos'
import Loader from '../components/Loader'
import { TodoAttachments } from '../components/Todos/TodoAttachments'

const Todo = (props) => {
  const { todoId } = props.match.params
  const isMounted = useRef(true)
  const [todo, setTodo] = useState({})
  const [comment, setComment] = useState('')
  const [isLoaded, setIsLoaded] = useState(false)

  useEffect(() => {
    fetchData()
    return () => (isMounted.current = false)
  }, [])

  const fetchData = () => {
    getTodo(todoId).then((data) => {
      if (isMounted.current) {
        setTodo(data)
        setIsLoaded(true)
      }
    })
  }

  const handleComplete = () => {
    markComplete(todoId).then((data) => {
      if (isMounted.current) {
        setTodo(data)
      }
    })
  }

  const handleSubmitComment = () => {
    submitComment(todoId, { message: comment }).then((data) => {
      if (data.success) {
        setComment('')
        fetchData()
      }
    })
  }

  const selectedIsLate = isLate(todo.due_date)
  const isComplete = todo.status === 'complete'
  const isClosed = todo.status === 'closed'
  const isOpen = todo.status === 'open'

  const statusButtonColor = isClosed ? 'gray' : 'success'
  const statusButtonText = () => {
    if (isClosed) {
      return 'Closed'
    } else if (isComplete) {
      return 'Marked Completed'
    }
    return 'Mark as Complete'
  }

  if (!isLoaded) return <Loader />
  return (
    <>
      <Container className='mt-3' fluid>
        <Row className='justify-content-end pr-5'>
          <Button
            color={statusButtonColor}
            outline={isComplete}
            type='button'
            onClick={handleComplete}
            disabled={!isOpen}
          >
            {isComplete && (
              <span className='btn-inner--icon pr-2'>
                <i className='ni ni-check-bold' />
              </span>
            )}
            <span className='btn-inner--text'>{statusButtonText()}</span>
          </Button>
        </Row>
        <Row className='mt-3 justify-content-center'>
          <Col className='px-5'>
            <Card>
              <CardBody>
                <div>
                  <h2>{todo.name}</h2>
                </div>
                <small style={{ color: selectedIsLate && 'red' }}>
                  {isClosed
                    ? `Closed on ${momentDateCreator(todo.updated_at).format(
                        'MMM Do[,] YYYY'
                      )}`
                    : `Due ${momentCountdown(todo.due_date)}`}
                </small>
              </CardBody>
            </Card>
            <Card>
              <CardHeader className='bg-transparent'>
                <h3 className='mb-0'>Description</h3>
              </CardHeader>
              <CardBody>{parse(todo.description)}</CardBody>
            </Card>
            <TodoAttachments attachments={todo.files} />
            <TodoHistory history={todo.history} />
            {!isClosed && (
              <Card>
                <CardHeader>
                  <h3 className='mb-0'>Add a Comment</h3>
                </CardHeader>
                <CardBody
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                  }}
                >
                  <FormGroup className='w-50'>
                    <Input
                      id='exampleFormControlTextarea3'
                      resize='none'
                      rows='5'
                      type='textarea'
                      value={comment}
                      onChange={(e) => {
                        setComment(e.target.value)
                      }}
                    />
                  </FormGroup>
                  <Button
                    color='primary'
                    type='button'
                    disabled={comment.length === 0}
                    className='mt-3'
                    onClick={handleSubmitComment}
                  >
                    Add Comment
                  </Button>
                </CardBody>
              </Card>
            )}
            {!isClosed && (
              <Card>
                <CardHeader>
                  <h3 className='mb-0'>Upload File</h3>
                </CardHeader>
                <CardBody
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                  }}
                >
                  <TodoDropZone fetchData={fetchData} todoId={todoId} />
                </CardBody>
              </Card>
            )}
          </Col>
        </Row>
      </Container>
    </>
  )
}

export default Todo
