const FunctionalRedirect = (props) => {
  const sessionProjects = JSON.parse(sessionStorage.getItem('projects'))
  if (sessionProjects)
    props.history.push(`/projects/${sessionProjects[0].id}/dashboard`)
  else {
    props.history.push('/login')
  }

  return null
}

export default FunctionalRedirect
