import React from 'react'
import { Card, CardHeader, CardBody, Row } from 'reactstrap'
import BurnDown from '../Charts/BurnDown'

const BurnDownCard = ({ project, taskData }) => {
  return (
    <Card className='bg-default'>
      <CardHeader className='bg-transparent'>
        <Row className='align-items-center'>
          <div className='col'>
            <h6 className='text-light text-uppercase ls-1 mb-1'>Overview</h6>
            <h5 className='h3 text-white mb-0'>User Stories Completion</h5>
          </div>
        </Row>
      </CardHeader>
      <CardBody>
        <div className='burnchart'>
          <BurnDown
            startDate={project && project.created_at}
            tasks={taskData}
          />
        </div>
      </CardBody>
    </Card>
  )
}

export default BurnDownCard
