import Api from './laravelConfig'

const loginFnc = (payload) => {
  return Api.axiosInitialize()
    .post('/customers/login', payload)
    .then((res) => {
      if (res && res.data) {
        if (res.data.data.accessToken) {
          const { id, firstname, lastname, projects } = res.data.data
          sessionStorage.setItem('userID', id)
          sessionStorage.setItem('userName', `${firstname} ${lastname}`)
          const sessionProjects = projects.map((project) => {
            return {
              id: project.id,
              asanaId: project.asana_id,
              name: project.name,
            }
          })
          sessionStorage.setItem('projects', JSON.stringify(sessionProjects))
        }
        return res.data
      }
    })
    .catch((error) => {
      if (error.response) {
        return error.response
      }
    })
}

export { loginFnc }
