import React from 'react'
import { isLate, momentDateCreator } from './momentFunctions'

const userID = JSON.parse(sessionStorage.getItem('userID'))

const blockType = (type) => {
  switch (type) {
    case 'status':
      return 'Status Update'
    case 'attachment':
      return 'New Attachment'
    default:
      return 'New Message'
  }
}

const blockIcon = (type) => {
  switch (type) {
    case 'status':
      return 'bell-55'
    case 'attachment':
      return 'folder-17'
    default:
      return 'chat-round'
  }
}

const blockColor = (type) => {
  switch (type) {
    case 'status':
      return 'success'
    case 'attachment':
      return 'warning'
    default:
      return 'info'
  }
}

const blockContext = (block) => {
  switch (block.type) {
    case 'status':
      return (
        <p
          className='text-sm mt-1 mb-0'
          style={{ textTransform: 'capitalize' }}
        >
          {block.status.name}
        </p>
      )
    case 'attachment':
      return (
        <a
          href={block.attachment.url}
          target='_blank'
          rel='noopener noreferrer'
        >
          {block.attachment.filename}
        </a>
      )
    default:
      return <p className='text-sm mt-1 mb-0'>{block.comment.message}</p>
  }
}

const todosSorter = (todos) => {
  return todos.sort((a, b) =>
    momentDateCreator(a.due_date).diff(momentDateCreator(b.due_date))
  )
}

const todosSeparator = (todos) => {
  let lateTodos = []
  let upComingTodos = []
  let closedTodos = []
  for (let i = 0; i < todos.length; i++) {
    if (todos[i].status === 'closed') {
      closedTodos.push(todos[i])
    } else {
      if (isLate(todos[i].due_date)) {
        lateTodos.push(todos[i])
      } else {
        upComingTodos.push(todos[i])
      }
    }
  }

  return { lateTodos, upComingTodos, closedTodos }
}

const newContent = (data) => {
  const {
    last_viewed,
    last_updated_by,
    last_updated_by_type,
    updated_at,
  } = data
  if (last_viewed) {
    if (momentDateCreator(updated_at).isAfter(momentDateCreator(last_viewed))) {
      if (last_updated_by) {
        if (last_updated_by_type === 'user' || last_updated_by !== userID) {
          return true
        }
      }
    }
    return false
  }
  return true
}

export {
  blockType,
  blockIcon,
  blockColor,
  blockContext,
  todosSeparator,
  newContent,
  todosSorter,
}
