import React from 'react'

export const NewContentAlert = ({ size }) => {
  return (
    <div
      style={{
        backgroundColor: '#ffc300',
        color: 'white',
        fontSize: size,
        textTransform: 'uppercase',
        padding: `${size / 2}px ${size}px`,
        borderRadius: '20px',
        fontWeight: 'bold',
        marginLeft: 5,
        boxShadow: '0px 3px 14px -4px rgba(117,117,117,0.68)',
      }}
    >
      New Content
    </div>
  )
}
