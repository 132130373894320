import React, { useContext } from 'react'
import { CardTitle, Card, CardBody, Row } from 'reactstrap'
import { todosSeparator } from '../../utils/todosFunctions'
import { NewContentAlert } from '../Todos/NewContentAlert'
import UserContext from '../Context/UserContext'

const TodosCard = (props) => {
  const { taskUpdated } = useContext(UserContext)
  const { todos, match } = props
  const { lateTodos, upComingTodos, closedTodos } = todosSeparator(todos)
  const { projectId } = match.params
  const isOverdue = () => {
    if (lateTodos.length > 0) {
      return 'text-danger'
    }
    return 'text-success'
  }
  const selectedTaskContext =
    taskUpdated &&
    taskUpdated.find((project) => project.project_id === JSON.parse(projectId))
  const showNewContentAlert =
    selectedTaskContext && selectedTaskContext.newContent

  return (
    <>
      <div style={{ display: 'flex', alignItems: 'center' }}>
        <CardTitle className='m-1 h2'>Todo's</CardTitle>
        {showNewContentAlert && <NewContentAlert size={10} />}
      </div>
      <Card className='card-stats'>
        <CardBody className='row justify-content-around align-items-center'>
          <Row>
            <div className='col text-center'>
              <CardTitle tag='h5' className='text-uppercase text-muted mb-0'>
                Completed
              </CardTitle>
              <span className='h2 font-weight-bold mb-0'>
                {closedTodos.length}
              </span>
            </div>
          </Row>
          <Row>
            <div className='col text-center'>
              <CardTitle tag='h5' className='text-uppercase text-muted mb-0'>
                Open
              </CardTitle>
              <span className='h2 font-weight-bold mb-0'>
                {upComingTodos.length}
              </span>
            </div>
          </Row>
          <Row>
            <div className='col text-center'>
              <CardTitle
                tag='h5'
                className={`text-uppercase mb-0 ${isOverdue()}`}
              >
                Overdue
              </CardTitle>
              <span className={`h2 font-weight-bold mb-0 ${isOverdue()}`}>
                {lateTodos.length}
              </span>
            </div>
          </Row>
        </CardBody>
      </Card>
    </>
  )
}

export default TodosCard
