import React, { useCallback, useState } from 'react'
import ReactCSSTransitionGroup from 'react-addons-css-transition-group'

import { CardBody, Button, Progress } from 'reactstrap'
import { useDropzone } from 'react-dropzone'
import { uploadFiles } from '../../data/todos'

const TodoDropZone = ({ fetchData, todoId }) => {
  const [files, setFiles] = useState([])
  const [isUploading, setIsUploading] = useState(false)
  const [uploadPercent, setUploadPercent] = useState(0)

  const onDrop = useCallback((acceptedFiles) => {
    acceptedFiles.forEach((file) => {
      //fix microsoft upload issue - found on github dropzone issues page
      if (!file.type) {
        var ext = file.name.split('.').pop()
      }
      if (ext === 'xls') {
        Object.defineProperty(file, 'type', {
          value: 'application/vnd.ms-excel',
        }) // MIME type
      }
      if (ext === 'xlsx') {
        Object.defineProperty(file, 'type', {
          value:
            'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
        }) // MIME type
      }
      if (ext === 'doc') {
        Object.defineProperty(file, 'type', {
          value: 'application/msword',
        }) // MIME type
      }
      if (ext === 'docx') {
        Object.defineProperty(file, 'type', {
          value:
            'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
        }) // MIME type
      }
    })
    const filesCopy = [...files, ...acceptedFiles]
    setFiles(filesCopy)
  })

  const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop })

  const removeFile = (index) => {
    setFiles([...files.slice(0, index), ...files.slice(index + 1)])
  }

  const handleSubmit = () => {
    let formData = new FormData()
    for (var i = 0; i < files.length; i++) {
      formData.append('files[]', files[i])
    }
    setIsUploading(true)
    uploadFiles(todoId, formData, setUploadPercent).then((data) => {
      if (data.success) {
        fetchData()
        setTimeout(() => {
          setFiles([])

          setIsUploading(false)
        }, 2000)
      }
    })
  }

  const fileDisplay = files.map((file, index) => {
    return (
      <div
        key={index}
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          padding: '1rem 3rem',
          width: '100%',
        }}
      >
        <div>{file.name}</div>
        <Button size='sm' color='danger' onClick={() => removeFile(index)}>
          <i className='fas fa-trash' />
        </Button>
      </div>
    )
  })

  return (
    <CardBody className='w-50'>
      <div {...getRootProps()} className='dropzone'>
        <input {...getInputProps()} />
        {isDragActive ? (
          <p className='mb-0'>Drop the files here ...</p>
        ) : (
          <p className='mb-0'>Click or Drop Files Here</p>
        )}
      </div>
      {files.length > 0 && (
        <div className='file-container'>
          <ReactCSSTransitionGroup
            transitionName='file'
            transitionAppear={true}
            transitionAppearTimeout={300}
            transitionEnterTimeout={300}
            transitionLeaveTimeout={300}
          >
            {fileDisplay}
          </ReactCSSTransitionGroup>
          <Button
            color='primary'
            type='button'
            className='mt-3'
            disabled={isUploading}
            onClick={handleSubmit}
          >
            Submit
          </Button>
        </div>
      )}
      {isUploading && (
        <div className='progress-wrapper'>
          <div className='progress-info'>
            <div className='progress-label'>
              <span>Uploading...</span>
            </div>
            <div className='progress-percentage'>
              <span>{uploadPercent}%</span>
            </div>
          </div>
          <Progress max='100' value={uploadPercent} color='info' />
        </div>
      )}
    </CardBody>
  )
}

export default TodoDropZone
