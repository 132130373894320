import React, { useState } from 'react'
import {
  Card,
  Col,
  CardBody,
  ListGroup,
  ListGroupItem,
  Collapse,
  Navbar,
  NavItem,
  NavLink,
  Nav,
} from 'reactstrap'
import classnames from 'classnames'
import moment from 'moment'

const UserStorySection = (props) => {
  const { tasks, section, first } = props
  const [isOpened, setIsOpened] = useState(first ? true : false)

  const renderTasks = (data) => {
    return data.map((task) => {
      return (
        <ListGroupItem
          key={task.gid}
          className='checklist-entry flex-column align-items-start py-4 px-4'
        >
          <div className='checklist-item checklist-item-success '>
            <div className='checklist-info'>
              <h3 className='checklist-title mb-0'>{task.name}</h3>
              <small>
                {task.completed
                  ? `Completed - ${moment(task.completed_at).format(
                      'MMM Do[,] YYYY'
                    )}`
                  : `Created - ${moment(task.created_at).format(
                      'MMM Do[,] YYYY'
                    )}`}
              </small>
            </div>
          </div>
        </ListGroupItem>
      )
    })
  }

  return (
    <Col>
      <Card>
        <Navbar
          style={{
            flex: 1,
            padding: 0,
            borderTopLeftRadius: 5,
            borderTopRightRadius: 5,
          }}
          className='navbar-vertical bg-white'
        >
          <Nav style={{ flex: 1, margin: 0 }} navbar>
            <NavItem>
              <NavLink
                href='#pablo'
                data-toggle='collapse'
                aria-expanded={isOpened}
                className={classnames({
                  active: true,
                  todoListTitle: true,
                })}
                onClick={(e) => {
                  e.preventDefault()
                  setIsOpened(!isOpened)
                }}
              >
                <h2 className='h2 mb-0'>{`${section.name} (${tasks.length})`}</h2>
              </NavLink>
            </NavItem>
          </Nav>
        </Navbar>
        <Collapse isOpen={isOpened}>
          <CardBody className='p-0'>
            <ListGroup data-toggle='checklist' flush>
              {tasks.length > 0 ? (
                renderTasks(tasks)
              ) : (
                <ListGroupItem className='checklist-entry flex-column align-items-start py-4 px-4'>
                  <div className='checklist-item checklist-item-success '>
                    <div className='checklist-info'>
                      <h3 className='checklist-title mb-0'>No Results</h3>
                    </div>
                  </div>
                </ListGroupItem>
              )}
            </ListGroup>
          </CardBody>
        </Collapse>
      </Card>
    </Col>
  )
}

export default UserStorySection
