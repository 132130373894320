import React, { useState } from 'react'
import UserContext from './UserContext'

const UserProvider = ({ children }) => {
  const userName = sessionStorage.getItem('userName')

  const setUserName = (input) => {
    setUser((prevState) => {
      return {
        ...prevState,
        userName: input,
      }
    })
  }

  const setTaskUpdated = (object) => {
    let taskUpdatedState = [...user.taskUpdated]
    const selectedIndex = taskUpdatedState.findIndex(
      (task) => task.project_id === object.project_id
    )
    if (selectedIndex >= 0) {
      taskUpdatedState[selectedIndex] = object
    } else {
      taskUpdatedState.push(object)
    }
    setUser((prevState) => {
      return {
        ...prevState,
        taskUpdated: taskUpdatedState,
      }
    })
  }

  const userState = {
    userName: userName ? userName : '',
    setUserName,
    taskUpdated: [],
    setTaskUpdated,
  }

  const [user, setUser] = useState(userState)

  return <UserContext.Provider value={user}>{children}</UserContext.Provider>
}

export default UserProvider
