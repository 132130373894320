import React, { useState } from 'react'
import {
  CardBody,
  ListGroup,
  ListGroupItem,
  Row,
  Col,
  Collapse,
  Navbar,
  NavItem,
  NavLink,
  Nav,
} from 'reactstrap'
import classnames from 'classnames'
import { TodosCollapsableItem } from './TodosCollapsableItem'
import { todosSorter } from '../../utils/todosFunctions'

export const TodoList = (props) => {
  const [collapse, setCollapse] = useState(props.defaultCollapsed)
  const { data, title, late, closed, icon } = props
  const titleColor = () => {
    if (closed) return '#2dce89'
    if (late) return '#f5365c'
    return 'black'
  }
  return (
    <Row>
      <Col>
        <Navbar
          style={{
            flex: 1,
            padding: 0,
            borderTopLeftRadius: 5,
            borderTopRightRadius: 5,
          }}
          className='navbar-vertical bg-white'
        >
          <Nav style={{ flex: 1, margin: 0 }} navbar>
            <NavItem>
              <NavLink
                href='#pablo'
                data-toggle='collapse'
                aria-expanded={collapse}
                className={classnames({
                  active: true,
                  todoListTitle: true,
                })}
                onClick={(e) => {
                  e.preventDefault()
                  setCollapse(!collapse)
                }}
              >
                <div
                  data-toggle='collapse'
                  aria-expanded={collapse}
                  style={{ display: 'flex', alignItems: 'center' }}
                >
                  {icon ? (
                    <i
                      className={icon}
                      style={{
                        fontSize: 20,
                        paddingRight: '0.75rem',
                        marginBottom: '0.25rem',
                        color: titleColor(),
                      }}
                    />
                  ) : null}
                  <h5
                    className='h3 mb-0 nav-link-text'
                    style={{ color: titleColor() }}
                  >
                    {`${title} (${data.length})`}
                  </h5>
                </div>
              </NavLink>
            </NavItem>
          </Nav>
        </Navbar>
        <Collapse isOpen={collapse}>
          <CardBody className='p-0'>
            <ListGroup data-toggle='checklist' flush>
              {data.length > 0 ? (
                todosSorter(data).map((obj, index) => {
                  return (
                    <TodosCollapsableItem
                      key={index}
                      data={obj}
                      late={late}
                      closed={closed}
                    />
                  )
                })
              ) : (
                <ListGroupItem className='checklist-entry flex-column align-items-start py-4 px-4'>
                  <div className='checklist-item checklist-item-white '>
                    <div className='checklist-info'>
                      <h3 className='checklist-title mb-0'>No Results</h3>
                    </div>
                  </div>
                </ListGroupItem>
              )}
            </ListGroup>
          </CardBody>
        </Collapse>
      </Col>
    </Row>
  )
}
