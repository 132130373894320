const filterUserStories = (tasks) =>
  tasks.filter((task) => {
    if (task && task.tags) {
      const userStoryTag = filterTags(task.tags)
      if (
        userStoryTag &&
        formatStringCompare(userStoryTag.name) === 'USERSTORY'
      ) {
        return task
      }
    }
    return {}
  })

const filterTags = (tags) => {
  return tags.filter((tag) => formatStringCompare(tag.name) === 'USERSTORY')[0]
}

const formatStringCompare = (str) => {
  return str.replace(/\s+/g, '').toUpperCase()
}

export { filterUserStories, formatStringCompare }
