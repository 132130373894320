import React, { useState } from 'react'
import { Card, CardHeader, Button, CardImg, Row, Col } from 'reactstrap'
import Anime from 'react-anime'

const ContactCard = ({ csr }) => {
  const [emailOpen, setEmailOpen] = useState(false)
  const [phoneOpen, setPhoneOpen] = useState(false)

  const formatPhoneNumber = (phoneNumberString) => {
    var cleaned = ('' + phoneNumberString).replace(/\D/g, '')
    var match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/)
    if (match) {
      return '(' + match[1] + ') ' + match[2] + '-' + match[3]
    }
    return null
  }

  return (
    <Card style={{ display: 'flex', height: '90%' }} className='card-profile'>
      <div style={{ height: '50%', overflow: 'hidden' }}>
        <CardImg
          alt='...'
          src={require('assets/img/theme/csr_background.png')}
          top
        />
      </div>
      <Row className='justify-content-center'>
        <Col className='order-lg-2' lg='3'>
          <div className='contact-card-image'>
            <img
              alt=''
              className='rounded-circle'
              style={{ width: '140px' }}
              src={
                csr.photo
                  ? csr.photo
                  : require('../../assets/img/theme/stock-profile.jpg')
              }
            />
          </div>
        </Col>
      </Row>
      <CardHeader
        style={{
          display: 'flex',
          flexDirection: 'column',
          flex: 1,
        }}
        className='text-center border-0'
      >
        <div className='d-flex justify-content-between'>
          <div
            onMouseEnter={() => setEmailOpen(true)}
            onMouseLeave={() => setEmailOpen(false)}
            className='d-flex justify-content-start'
            style={{ flexDirection: 'column' }}
          >
            <Button
              className='btn-icon-only rounded-circle'
              color='twitter'
              rel='noopener noreferrer'
              onClick={(e) => {
                e.preventDefault()
                return window.open(`mailto:${csr.email}`, '_blank')
              }}
            >
              <i className='ni ni-email-83' />
            </Button>

            <Anime
              key={'email' + Date.now()}
              opacity={[0, emailOpen ? 1 : 0]}
              translateY={[0, emailOpen ? 15 : -15]}
            >
              <a
                rel='noopener noreferrer'
                className={`text-muted pb-2`}
                href={`mailto:${csr.email}`}
                target='_blank'
                style={styles.link_text}
              >
                {csr.email}
              </a>
            </Anime>
          </div>
          <div
            onMouseEnter={() => setPhoneOpen(true)}
            onMouseLeave={() => setPhoneOpen(false)}
            className='text-right'
            style={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'flex-end',
            }}
          >
            <Button
              className='btn-icon-only rounded-circle'
              color='facebook'
              onClick={(e) => {
                e.preventDefault()
                return window.location.assign(`tel:${csr.phone}`)
              }}
            >
              <i className='ni ni-mobile-button' />
            </Button>
            <Anime
              key={'phone' + Date.now()}
              opacity={[0, phoneOpen ? 1 : 0]}
              translateY={[0, phoneOpen ? 15 : 0]}
            >
              <a
                href={`tel:${csr.phone}`}
                className='text-muted pb-2'
                style={styles.link_text}
              >
                {formatPhoneNumber(csr.phone)}
              </a>
            </Anime>
          </div>
        </div>
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'space-around',
            paddingTop: '5%',
          }}
        >
          <h5 className='h2'>{csr.fullname}</h5>
          <div className='h3 font-weight-300'>
            <span className='font-weight-light'>
              Your Project Representative
            </span>
          </div>
        </div>
      </CardHeader>
    </Card>
  )
}

const styles = {
  link_text: {
    marginBottom: 0,
    paddingBottom: 0,
    marginLeft: 10,
  },
  contact_links: {
    marginTop: 20,
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'center',
  },
  contact_links_container: {
    display: 'flex',
    flexDirection: 'column',
    flexBasis: '50%',
  },
}

export default ContactCard
