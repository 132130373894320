import React, { useState, useEffect, useRef } from 'react'
// reactstrap components
import { Container } from 'reactstrap'
import { getSections, getTasks } from '../data/asanaData'
import Loader from '../components/Loader'
import UserStorySection from '../components/UserStories/UserStorySection'
import getAsanaProjectId from '../utils/asanaId'

const UserStories = (props) => {
  const { projectId } = props.match.params
  const isMounted = useRef(true)
  const [tasks, setTasks] = useState([])
  const [sections, setSections] = useState([])
  const [isLoaded, setIsLoaded] = useState(false)

  useEffect(() => {
    return () => {
      return (isMounted.current = false)
    }
  }, [])

  useEffect(() => {
    setIsLoaded(false)
    fetchData()
  }, [projectId])

  const fetchData = () => {
    const asanaProjectId = getAsanaProjectId(props.match.params)
    getSections(asanaProjectId).then((data) => {
      if (isMounted.current) {
        setSections(data)
      }
    })
    getTasks(
      asanaProjectId,
      'name,completed,created_at,completed_at,memberships.section.gid,assignee_status'
    ).then((data) => {
      if (isMounted.current) {
        setTasks(data)
        setIsLoaded(true)
      }
    })
  }

  const filterCompletedTasks = () => {
    return tasks.filter((task) => task.completed)
  }

  const renderSections = () => {
    return sections.map((section, i) => {
      const sectionTasks = tasks.filter(
        (task) =>
          task.memberships[0].section.gid === section.gid && !task.completed
      )
      return (
        <UserStorySection
          key={section.gid}
          section={section}
          first={i === 0}
          tasks={sectionTasks}
        />
      )
    })
  }

  if (!isLoaded) {
    return <Loader />
  }
  return (
    <>
      <Container className='mt-3' fluid>
        {renderSections()}
        <UserStorySection
          section={completedSection}
          tasks={filterCompletedTasks()}
        />
      </Container>
    </>
  )
}

const completedSection = {
  gid: '1',
  name: 'Completed',
}

export default UserStories
