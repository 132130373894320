import React, { useState, useEffect } from 'react'
import AuthHeader from 'components/Headers/AuthHeader.jsx'
import {
  Button,
  Card,
  CardBody,
  FormGroup,
  Form,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Container,
  Row,
  Col,
} from 'reactstrap'
import classnames from 'classnames'
import Swal from 'sweetalert2'

import { setNewPassword, resetPassword } from '../../../data/password'

const SetPassword = (props) => {
  const { token } = props.match.params
  const [password, setPassword] = useState({ password: '', confirm: '' })
  const [focusedPassword, setFocusedPassword] = useState(false)
  const [focusedConfirm, setFocusedConfirm] = useState(false)
  const [confirmValid, setConfirmValid] = useState(true)
  const samePassword = password.password === password.confirm

  useEffect(() => {
    if (!confirmValid) {
      setConfirmValid(samePassword)
    }
  }, [password])

  const handleChange = (e) => {
    const { name, value } = e.target
    setPassword({ ...password, [name]: value })
  }

  const checkRoute = (payload) => {
    if (props.reset) return resetPassword(payload)
    return setNewPassword(payload)
  }

  const handleSubmit = (e) => {
    e.preventDefault()
    const payload = {
      verification_token: token,
      password: password.password,
    }
    checkRoute(payload).then((data) => {
      if (data.status === 'ok') {
        Swal.fire({
          title: 'New Password Created',
          icon: 'success',
          confirmButtonText: 'Take me to the Login Page',
          onClose: () => props.history.push('/login'),
        })
      } else {
        const error = data.data && data.data.error
        if (error && error.length > 0) {
          Swal.fire('Oops...', error.join('. '), 'error')
        }
      }
    })
  }

  const enableButton = password.password.length > 0 && samePassword

  return (
    <>
      <AuthHeader title='Project Liftoff' lead='Weclome!' />
      <Container className='mt--8 pb-5'>
        <Row className='justify-content-center'>
          <Col lg='5' md='7'>
            <Card className='bg-secondary border-0 mb-0'>
              <CardBody className='px-lg-5 py-lg-5'>
                <div className='text-center text-muted mb-4'>
                  <small>Create New Password Below</small>
                </div>
                <Form role='form'>
                  <FormGroup
                    className={classnames('mb-3', {
                      focused: !confirmValid ? false : focusedPassword,
                      error: !confirmValid,
                    })}
                  >
                    <label className='form-control-label' htmlFor='newPassword'>
                      New Password
                    </label>
                    <InputGroup
                      className='input-group-merge input-group-alternative'
                      id='newPassword'
                    >
                      <InputGroupAddon addonType='prepend'>
                        <InputGroupText>
                          <i className='ni ni-key-25' />
                        </InputGroupText>
                      </InputGroupAddon>
                      <Input
                        placeholder='New Password'
                        type='password'
                        name='password'
                        value={password.password}
                        onChange={handleChange}
                        onFocus={() => setFocusedPassword(true)}
                        onBlur={() => setFocusedPassword(false)}
                      />
                    </InputGroup>
                  </FormGroup>
                  <FormGroup
                    className={classnames('mb-3', {
                      focused: !confirmValid ? false : focusedConfirm,
                      error: !confirmValid,
                    })}
                  >
                    <label
                      className='form-control-label'
                      htmlFor='confirmPassword'
                    >
                      Confirm Password
                    </label>
                    <InputGroup
                      className='input-group-merge input-group-alternative'
                      id='confirmPassword'
                    >
                      <InputGroupAddon addonType='prepend'>
                        <InputGroupText>
                          <i className='ni ni-key-25' />
                        </InputGroupText>
                      </InputGroupAddon>
                      <Input
                        placeholder='Confirm Password'
                        type='password'
                        name='confirm'
                        value={password.confirm}
                        onChange={handleChange}
                        onFocus={() => setFocusedConfirm(true)}
                        onBlur={() => {
                          setConfirmValid(samePassword)
                          setFocusedConfirm(false)
                        }}
                      />
                    </InputGroup>
                    {!confirmValid && (
                      <div
                        style={{
                          width: '100%',
                          marginTop: '0.25rem',
                          fontSize: '80%',
                          color: '#fb6340',
                        }}
                      >
                        Passwords are not the same!
                      </div>
                    )}
                  </FormGroup>
                  <div className='text-center'>
                    <Button
                      className='my-4'
                      color={enableButton ? 'success' : 'default'}
                      type='submit'
                      onClick={
                        enableButton ? handleSubmit : (e) => e.preventDefault()
                      }
                    >
                      Set Password
                    </Button>
                  </div>
                </Form>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </>
  )
}

export default SetPassword
