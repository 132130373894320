import React, { useState, useCallback } from 'react'
import { Bar } from 'react-chartjs-2'
import moment from 'moment'
import { Card, CardHeader, Row, CardBody } from 'reactstrap'

const Hours = (props) => {
  const { hours } = props

  const [dimensions, setDimensions] = useState({
    left: 0,
    right: 0,
    top: 0,
    bottom: 0,
  })

  const measuredRef = useCallback((node) => {
    if (node !== null) {
      setTimeout(() => {
        const { width, height } = node.chartInstance
        setDimensions({ ...dimensions, right: width, bottom: height })
      }, 250)
    }
  }, [])

  const formatData = () => {
    return hours.map((hour) => {
      return {
        x: `${hour.start_date} - ${hour.end_date}`,
        y: hour.hours,
      }
    })
  }

  const labels = () => {
    return hours.map((hour) => {
      return `${moment(hour.start_date).format('MMM D')} - ${moment(
        hour.end_date
      ).format('MMM D')}`
    })
  }

  const chartData = (canvas) => {
    const { left, right, top, bottom } = dimensions

    const ctx = canvas.getContext('2d')

    const xAvg = (left + right) / 2

    const gradientOrange = ctx.createLinearGradient(xAvg, bottom, xAvg, top)

    gradientOrange.addColorStop(0, '#FA7B7E')
    gradientOrange.addColorStop(1, '#FDB169')
    return {
      labels: labels(),
      datasets: [
        {
          label: 'Hours by Sprint',
          data: formatData(),
          backgroundColor: gradientOrange,
          borderWidth: 1,
          borderColor: gradientOrange,
        },
      ],
    }
  }

  return (
    <Card style={{ height: '90%' }}>
      <CardHeader className='bg-transparent'>
        <Row className='align-items-center'>
          <div className='col'>
            <h6 className='text-uppercase text-muted ls-1 mb-1'>Performance</h6>
            <h5 className='h3 mb-0'>Hours Breakdown</h5>
          </div>
        </Row>
      </CardHeader>
      <CardBody>
        <div style={{ width: '100%' }} className='chart pb-1'>
          <Bar ref={measuredRef} data={chartData} options={chartOptions} />
        </div>
      </CardBody>
    </Card>
  )
}

const chartOptions = {
  responsive: true,
  maintainAspectRatio: false,
  scales: {
    xAxes: [
      {
        barThickness: 50,
      },
    ],
    yAxes: [
      {
        ticks: {
          beginAtZero: true,
        },
      },
    ],
  },
}

export default Hours
