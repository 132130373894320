const locationSwitch = (location) => {
  switch (location) {
    case 'todos':
      return "To Do's"
    case 'dashboard':
      return 'Dashboard'
    case 'userStories':
      return 'User Stories'
    default:
      return ''
  }
}

export { locationSwitch }
