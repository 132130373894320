import React from 'react'
// nodejs library to set properties for components
import PropTypes from 'prop-types'
// reactstrap components
import { Container, Row, Col } from 'reactstrap'
import StatusBarCard from '../Dashboard/StatusBarCard'
import TodosCard from '../Dashboard/TodosCard'
import UserStoriesCard from '../Dashboard/UserStoriesCard'

const CardsHeader = (props) => {
  const { tasks, status, todos } = props

  return (
    <div className='header'>
      <Container fluid>
        <div className='header-body'>
          <Row>
            <Col sm='12' style={{ height: '50%' }}>
              <StatusBarCard status={status ? status : {}} />
            </Col>
            <Col md='6' xl='6'>
              <TodosCard todos={todos} {...props} />
            </Col>
            <Col md='6' xl='6'>
              <UserStoriesCard tasks={tasks} />
            </Col>
          </Row>
        </div>
      </Container>
    </div>
  )
}

CardsHeader.propTypes = {
  name: PropTypes.string,
  parentName: PropTypes.string,
}

export default CardsHeader
