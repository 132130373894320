/*!

=========================================================
* Argon Dashboard PRO React - v1.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-pro-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React, { useState, useEffect, useContext } from 'react'
// nodejs library that concatenates classes
import classnames from 'classnames'
// reactstrap components
import {
  Button,
  Card,
  CardBody,
  FormGroup,
  Form,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Container,
  Row,
  Col,
} from 'reactstrap'
import Swal from 'sweetalert2'
// core components

import AuthHeader from 'components/Headers/AuthHeader.jsx'
import { loginFnc } from '../../../data/login'
import UserContext from '../../../components/Context/UserContext'
import { Link } from 'react-router-dom'

const Login = (props) => {
  const rememberMeLocal = localStorage.getItem('remember_me')

  const [focusedEmail, setFocusedEmail] = useState(false)
  const [focusedPassword, setFocusedPassword] = useState(false)
  const [rememberMe, setRememberMe] = useState(JSON.parse(rememberMeLocal))
  const [login, setLogin] = useState({ email: '', password: '' })

  const { setUserName } = useContext(UserContext)

  useEffect(() => {
    if (rememberMe) {
      const email = localStorage.getItem('login_email')
      email && setLogin({ ...login, email })
    }
  }, [])
  const handleChange = (e) => {
    const { name, value } = e.target
    setLogin({ ...login, [name]: value })
  }

  const handleSubmit = () => {
    localStorage.setItem('login_email', rememberMe ? login.email : '')
    loginFnc(login).then((res) => {
      if (res && res.status === 'ok') {
        const { firstname, lastname, accessToken } = res.data
        setUserName(`${firstname} ${lastname}`)
        sessionStorage.setItem('access_token', accessToken)
        props.history.push('/projects')
      } else {
        const error = res.data && res.data.error
        if (error && error.length > 0) {
          Swal.fire('Oops...', error.join('. '), 'error')
        }
      }
    })
  }

  const handleEnter = (e) => {
    if (e.keyCode === 13) {
      handleSubmit()
    }
  }

  const handleRememberMe = () => {
    localStorage.setItem('remember_me', !rememberMe)
    setRememberMe(!rememberMe)
  }

  const enableButton = login.email.length > 0 && login.password.length > 0
  const accessToken = sessionStorage.getItem('access_token')
  if (accessToken) props.history.push('/projects')
  return (
    <>
      <AuthHeader title='Liftoff' lead='Welcome' />
      <Container className='mt--8 pb-5'>
        <Row className='justify-content-center'>
          <Col lg='5' md='7'>
            <Card className='bg-secondary border-0 mb-0'>
              <CardBody className='px-lg-5 py-lg-5'>
                <div className='text-center text-muted mb-4'>
                  <small>Sign In</small>
                </div>
                <Form role='form'>
                  <FormGroup
                    className={classnames('mb-3', {
                      focused: focusedEmail,
                    })}
                  >
                    <InputGroup className='input-group-merge input-group-alternative'>
                      <InputGroupAddon addonType='prepend'>
                        <InputGroupText>
                          <i className='ni ni-email-83 pr-3' />
                        </InputGroupText>
                      </InputGroupAddon>
                      <Input
                        placeholder='Email'
                        type='email'
                        name='email'
                        value={login.email}
                        onChange={handleChange}
                        onFocus={() => setFocusedEmail(true)}
                        onBlur={() => setFocusedEmail(false)}
                      />
                    </InputGroup>
                  </FormGroup>
                  <FormGroup
                    className={classnames({
                      focused: focusedPassword,
                    })}
                  >
                    <InputGroup className='input-group-merge input-group-alternative'>
                      <InputGroupAddon addonType='prepend'>
                        <InputGroupText>
                          <i className='ni ni-lock-circle-open pr-3' />
                        </InputGroupText>
                      </InputGroupAddon>
                      <Input
                        placeholder='Password'
                        type='password'
                        name='password'
                        value={login.password}
                        onChange={handleChange}
                        onKeyDown={enableButton ? handleEnter : null}
                        onFocus={() => setFocusedPassword(true)}
                        onBlur={() => setFocusedPassword(false)}
                      />
                    </InputGroup>
                  </FormGroup>
                  <div className='custom-control custom-control-alternative custom-checkbox'>
                    <input
                      className='custom-control-input'
                      id=' customCheckLogin'
                      type='checkbox'
                      checked={rememberMe}
                      onChange={handleRememberMe}
                    />
                    <label
                      className='custom-control-label'
                      htmlFor=' customCheckLogin'
                    >
                      <span className='text-muted'>Remember me</span>
                    </label>
                  </div>
                  <div className='text-center'>
                    <Button
                      className='my-4'
                      color={enableButton ? 'success' : 'default'}
                      type='button'
                      onClick={enableButton ? handleSubmit : null}
                    >
                      Sign in
                    </Button>
                  </div>
                </Form>
              </CardBody>
            </Card>
            <Row className='mt-3'>
              <Col xs='6'>
                <Link className='text-light' to='/forgotPassword'>
                  <small>Forgot password?</small>
                </Link>
              </Col>
            </Row>
          </Col>
        </Row>
      </Container>
    </>
  )
}

export default Login
