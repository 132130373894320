import React from 'react'
import { CardTitle, Card, CardBody, Row } from 'reactstrap'
import { formatStringCompare } from '../../utils/tasksFunctions'

const UserStoriesCard = (props) => {
  const { tasks } = props

  const filterCompletedTasksCount = (tasks) => {
    return tasks.filter((task) => task.completed === true).length
  }

  const filterBacklogTasks = (tasks) => {
    return tasks.filter(
      (task) =>
        formatStringCompare(task.memberships[0].section.name) === 'BACKLOG' &&
        !task.completed
    ).length
  }

  const filterInProgressTasks = (tasks) => {
    return tasks.filter((task) => {
      return (
        formatStringCompare(task.memberships[0].section.name) !== 'BACKLOG' &&
        !task.completed
      )
    }).length
  }

  return (
    <>
      <CardTitle className='m-1 h2'>User Stories</CardTitle>
      <Card className='card-stats'>
        <CardBody className='row justify-content-around align-items-center'>
          <Row>
            <div className='col text-center'>
              <CardTitle tag='h5' className='text-uppercase text-muted mb-0'>
                Completed
              </CardTitle>
              <span className='h2 font-weight-bold mb-0'>
                {filterCompletedTasksCount(tasks)}
              </span>
            </div>
          </Row>
          <Row>
            <div className='col text-center'>
              <CardTitle tag='h5' className='text-uppercase text-muted mb-0'>
                In Progress
              </CardTitle>
              <span className='h2 font-weight-bold mb-0'>
                {filterInProgressTasks(tasks)}
              </span>
            </div>
          </Row>
          <Row>
            <div className='col text-center'>
              <CardTitle tag='h5' className='text-uppercase text-muted mb-0'>
                Back Log
              </CardTitle>
              <span className='h2 font-weight-bold mb-0'>
                {filterBacklogTasks(tasks)}
              </span>
            </div>
          </Row>
        </CardBody>
      </Card>
    </>
  )
}

export default UserStoriesCard
