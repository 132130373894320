import React, { useEffect, useRef, useState, useLayoutEffect } from 'react'
import anime from 'animejs/lib/anime.es.js'

const Loader = (props) => {
  const imgRef = useRef()
  const [dimensions, setDimensions] = useState({})

  useEffect(() => {
    animate()
  }, [])

  useLayoutEffect(() => {
    const height = imgRef.current.clientHeight
    const width = imgRef.current.clientWidth
    setDimensions({ height, width })
  }, [imgRef.current])

  const animate = () =>
    anime({
      targets: '#lineDrawing .lines path',
      strokeDashoffset: [anime.setDashoffset, 0],
      easing: 'easeInOutSine',
      duration: 250,
      delay: function(el, i) {
        return i * 25
      },
      direction: 'alternate',
      loop: true,
    })

  const { width } = dimensions
  const divWidth = width ? width : 650

  return (
    <div style={{ display: 'flex', justifyContent: 'center' }} ref={imgRef}>
      <div
        id='lineDrawing'
        style={{
          width: divWidth,
          display: 'flex',
          justifyContent: 'center',
          marginTop: '5rem',
          marginBottom: '5rem',
        }}
      >
        <svg height={300} width={300}>
          <g className='lines'>
            <path
              className='my-path'
              d='M131.21,14.08c0,0-40.19,29.58,9.49,171.35c0,0,12.84,1.12,17.86,0c0,0,47.44-113.86,10.6-171.35
	C169.16,14.08,145.72,7.38,131.21,14.08z'
            />
            <path
              className='my-path'
              d='M174.75,14.08c0,0,39.63,31.13-11.16,171.84h12.94c0,0,91.43-99.29,32.27-160.68
	C208.79,25.24,198.74,16.97,174.75,14.08z'
            />
            <path
              className='my-path'
              d='M214.93,25.24c0,0,59.84,49.55-31.76,160.68c0,0,3.12,0.06,12.97-2.17C196.15,183.75,312.05,84.96,214.93,25.24
	z'
            />
            <path
              className='my-path'
              d='M93.26,25.24c0,0-64.74,45.94,29.58,160.68c0,0,7.81,0.5,12.84,0c0,0-45.21-108.78-12.84-171.84
	C122.84,14.08,100.69,17.81,93.26,25.24z'
            />
            <path
              className='my-path'
              d='M83.95,25.24c0,0-95.63,45.93,17.86,160.68c0,0,10.05,0.68,11.91,0C113.72,185.92,28.89,79.94,83.95,25.24z'
            />
            <path
              className='my-path'
              d='M107.77,189.7c0,0,53.21,4.09,83.35,0c0,0-13.38,17.77-41.6,19.16S107.77,189.7,107.77,189.7z'
            />
            <path
              className='my-path'
              d='M130.51,212.22c0,0,1.4,1.26,5.16,1.4v17.72c0,0-3.63-0.56-4.6-2.37L130.51,212.22z'
            />
            <path
              className='my-path'
              d='M146.98,214.31c0,0,3.01,0.84,5.2,0v18.42c0,0-3.95,0.98-5.2,0V214.31z'
            />
            <path
              className='my-path'
              d='M163.58,213.61c0,0,3.35,0.14,5.02-1.4l-0.56,16.47c0,0-1.95,3.21-5.16,3.35L163.58,213.61z'
            />
            <path
              className='my-path'
              d='M127.02,236.63v30.84c0,0,1.26,2.93,3.49,3.07c2.23,0.14,38.09,0,38.09,0s3.63,0.14,3.63-2.65
	c0-2.79,0-29.58,0-29.58s-0.28-4.19-3.63-4.19s-5.94,5.16-19.09,4.6c-13.15-0.56-19.01-4.6-19.01-4.6S127.02,233.43,127.02,236.63z'
            />
          </g>
        </svg>
      </div>
    </div>
  )
}

export default Loader
