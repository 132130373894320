import Api from './laravelConfig'

const getUser = () => {
  return Api.axiosInstance()
    .get(`/me`)
    .then((res) => {
      if (res && res.data) {
        return res.data
      }
    })
    .catch((error) => {
      if (error.response) {
        return error.response
      }
    })
}

const updateUser = (payload) => {
  return Api.axiosInstance()
    .post(`/me`, payload)
    .then((res) => {
      if (res && res.data) {
        return res.data
      }
    })
    .catch((error) => {
      if (error.response) {
        return error.response
      }
    })
}

export { getUser, updateUser }
