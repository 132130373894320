/*!

=========================================================
* Argon Dashboard PRO React - v1.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-pro-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from 'react'
import ReactDOM from 'react-dom'
// react library for routing
import { BrowserRouter, Route, Switch, Redirect } from 'react-router-dom'

// plugins styles from node_modules
import 'react-notification-alert/dist/animate.css'
import 'react-perfect-scrollbar/dist/css/styles.css'
// plugins styles downloaded
import 'assets/vendor/fullcalendar/dist/fullcalendar.min.css'
import 'assets/vendor/sweetalert2/dist/sweetalert2.min.css'
import 'assets/vendor/select2/dist/css/select2.min.css'
import 'assets/vendor/quill/dist/quill.core.css'
import 'assets/vendor/nucleo/css/nucleo.css'
import 'assets/vendor/@fortawesome/fontawesome-free/css/all.min.css'
// core styles
import 'assets/scss/argon-dashboard-pro-react.scss?v1.0.0'

import AdminLayout from 'layouts/Admin.jsx'
import Login from 'views/pages/examples/Login.jsx'
import IndexView from 'views/Index.jsx'
import FunctionalRedirect from './views/FunctionalRedirect'
import ForgotPassword from './views/pages/examples/ForgotPassword'

import UserProvider from './components/Context/UserProvider'
import SetPassword from './views/pages/examples/SetPassword'

ReactDOM.render(
  <BrowserRouter basename='/'>
    <UserProvider>
      <Switch>
        <Route path='/admin' render={(props) => <AdminLayout {...props} />} />
        <Route path='/login' render={(props) => <Login {...props} />} />
        <Route
          path='/forgotPassword'
          render={(props) => <ForgotPassword {...props} />}
        />
        <Route
          path='/setPassword/:token'
          render={(props) => <SetPassword {...props} />}
        />
        <Route
          path='/resetPassword/:token'
          render={(props) => <SetPassword {...props} reset={true} />}
        />
        <Route path='/user' render={(props) => <IndexView {...props} />} />
        <Route
          path='/projects/:projectId'
          render={(props) => <IndexView {...props} />}
        />
        <Route
          path='/redirect'
          render={(props) => <FunctionalRedirect {...props} />}
        />
        <Redirect from='/*' to='/redirect' />
      </Switch>
    </UserProvider>
  </BrowserRouter>,
  document.getElementById('root')
)
