import Api from './laravelConfig'

const getProjectLaravel = (id) => {
  return Api.axiosInstance()
    .get(`/projects/${id}`)
    .then((res) => {
      return res.data.data
    })
    .catch((error) => {
      throw error
    })
}

export { getProjectLaravel }
