import React from 'react'
import { ListGroupItem } from 'reactstrap'
import { NavLink } from 'react-router-dom'

import { momentCountdown, momentDateCreator } from '../../utils/momentFunctions'
import { newContent } from '../../utils/todosFunctions'

export const TodosCollapsableItem = (props) => {
  const { data, late, closed } = props
  const { due_date, name, id, updated_at } = data
  const classNameContainer = `checklist-item checklist-item-${
    late ? 'danger' : closed ? 'success' : 'white'
  }`

  return (
    <ListGroupItem style={{ padding: 0 }}>
      <NavLink to={`todos/${id}`} style={{ color: 'black', flex: 1 }}>
        <div className='checklist-entry flex-column align-items-start py-4 px-4'>
          <div className={classNameContainer}>
            <div className='checklist-info' style={{ maxWidth: '80%' }}>
              <h5 className='checklist-title mb-0'>{name}</h5>
              <small style={{ color: late ? 'red' : 'gray' }}>
                {closed
                  ? `Closed on ${momentDateCreator(updated_at).format(
                      'MMM Do[,] YYYY'
                    )}`
                  : momentCountdown(due_date)}
              </small>
            </div>
            {newContent(data) && (
              <div
                style={{
                  backgroundColor: '#ffc300',
                  padding: 10,
                  borderRadius: 5,
                  boxShadow: '0px 3px 14px -4px rgba(117,117,117,0.68)',
                }}
              >
                <h5 style={{ margin: 0, color: 'white' }}>New</h5>
              </div>
            )}
          </div>
        </div>
      </NavLink>
    </ListGroupItem>
  )
}
