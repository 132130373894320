/*!

=========================================================
* Argon Dashboard PRO React - v1.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-pro-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import Dashboard from 'views/pages/dashboards/Dashboard.jsx'
import Todos from './views/Todos'
import UserStories from './views/UserStories'
import Todo from './views/Todo'
import UserForm from './views/UserForm'

const routes = () => {
  const sessionProjects = JSON.parse(sessionStorage.getItem('projects'))
  let projects = sessionProjects.map((project) => {
    return {
      collapse: true,
      name: project.name,
      icon: 'ni ni-collection text-helium',
      state: `dashboardCollapse${project.id}`,
      views: [
        {
          path: '/dashboard',
          name: 'Dashboard',
          component: Dashboard,
          layout: '/projects/:projectId',
          routeTo: `/projects/${project.id}/dashboard`,
        },
        {
          path: '/todos',
          name: "Todo's",
          component: Todos,
          layout: '/projects/:projectId',
          routeTo: `/projects/${project.id}/todos`,
          projectId: project.id,
        },
        {
          path: '/userStories',
          name: 'User Stories',
          component: UserStories,
          layout: '/projects/:projectId',
          routeTo: `/projects/${project.id}/userStories`,
        },
      ],
    }
  })
  let finalProject = [...projects]
  for (let i = 0; i < projects.length; i++) {
    finalProject = [
      {
        path: '/todos/:todoId',
        name: 'Charts',
        icon: 'ni ni-chart-pie-35 text-info',
        component: Todo,
        layout: '/projects/:projectId',
        redirect: true,
      },
      ...finalProject,
    ]
  }
  finalProject = [
    ...finalProject,
    {
      path: '/',
      name: 'UserForm',
      icon: 'ni ni-chart-pie-35 text-info',
      component: UserForm,
      layout: '/user',
      redirect: true,
    },
  ]
  return finalProject
}

export default routes
