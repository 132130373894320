import Api from './apiConfig'

// name,completed,created_at,completed_at,memberships.section.gid,assignee_status
const getTasks = (projectId, fields) =>
  Api.asanaInstance()
    .get(`/projects/${projectId}/tasks?opt_fields=${fields}`)
    .then((res) => {
      return res.data.data
    })
    .catch((error) => {
      throw error
    })

const getSections = (projectId) =>
  Api.asanaInstance()
    .get(`/projects/${projectId}/sections`)
    .then((res) => {
      return res.data.data
    })
    .catch((error) => {
      throw error
    })

const getProject = (projectId) => {
  return Api.asanaInstance()
    .get(`/projects/${projectId}`)
    .then((res) => {
      return res.data.data
    })
    .catch((error) => {
      throw error
    })
}

export { getTasks, getSections, getProject }
