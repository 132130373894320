/*!

=========================================================
* Argon Dashboard PRO React - v1.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-pro-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React, { useState, useEffect, useRef, useContext } from 'react'
// node.js library that concatenates classes (strings)
import Chart from 'chart.js'
// reactstrap components
import { Container, Row, Col } from 'reactstrap'

// core components
import CardsHeader from 'components/Headers/CardsHeader.jsx'
import { chartOptions, parseOptions } from 'variables/charts.jsx'
import { getProject, getTasks } from '../../../data/asanaData'
import { getTodos } from '../../../data/todos'
import { getProjectLaravel } from '../../../data/projectData'
import Loader from '../../../components/Loader'
import getAsanaProjectId from '../../../utils/asanaId'
import Hours from '../../../components/Charts/Hours'
import ContactCard from '../../../components/Dashboard/ContactCard'
import BurnDownCard from '../../../components/Dashboard/BurnDownCard'
import UserContext from '../../../components/Context/UserContext'
import { newContent } from '../../../utils/todosFunctions'
import { filterUserStories } from '../../../utils/tasksFunctions'

const Dashboard = (props) => {
  const isMounted = useRef(true)
  const { projectId } = props.match.params

  const [taskData, setTaskData] = useState([])
  const [project, setProject] = useState({
    created_at: '',
    current_status: {},
  })
  const [isLoaded, setIsLoaded] = useState(false)
  const [laravelProject, setLaravelProject] = useState({
    csr: {},
    sprint_hours: [],
  })
  const [todos, setTodos] = useState([])
  const { setTaskUpdated } = useContext(UserContext)

  useEffect(() => {
    setIsLoaded(false)
    fetchData()
  }, [projectId])

  useEffect(() => {
    if (window.chart) {
      parseOptions(Chart, chartOptions())
    }
    return () => {
      isMounted.current = false
    }
  }, [])

  const fetchData = async () => {
    const asanaProjectId = getAsanaProjectId(props.match.params)
    const project = await getProject(asanaProjectId)
    const projectLaravel = await getProjectLaravel(props.match.params.projectId)
    const todos = await getTodos(props.match.params.projectId)
    const tasks = await getTasks(
      asanaProjectId,
      'memberships.section.name,completed,created_at,completed_at,tags.name'
    )
    const userSotryTasks = filterUserStories(tasks)
    if (isMounted.current) {
      project && setProject(project)
      tasks && setTaskData(userSotryTasks)
      projectLaravel && setLaravelProject(projectLaravel)
      todos && setTodos(todos)
      setIsLoaded(true)
      for (let i = 0; i < todos.length; i++) {
        if (newContent(todos[i])) {
          setTaskUpdated({ project_id: todos[i].project_id, newContent: true })
          break
        }
        if (i === todos.length - 1) {
          setTaskUpdated({ project_id: todos[i].project_id, newContent: false })
        }
      }
    }
  }

  if (!isLoaded) {
    return <Loader />
  }

  return (
    <>
      <CardsHeader
        status={project.current_status}
        tasks={taskData}
        name='Default'
        parentName='Dashboards'
        todos={todos}
        {...props}
      />
      <Container fluid>
        <Row>
          <Col xl='12'>
            <BurnDownCard project={project} taskData={taskData} />
          </Col>
        </Row>
        <Row>
          <Col xl='6'>
            <Hours
              hours={
                laravelProject.sprint_hours ? laravelProject.sprint_hours : []
              }
            />
          </Col>
          <Col xl='6'>
            <ContactCard csr={laravelProject.csr ? laravelProject.csr : {}} />
          </Col>
        </Row>
      </Container>
    </>
  )
}

export default Dashboard
