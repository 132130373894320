import Api from './laravelConfig'

const forgotPassword = (payload) => {
  return Api.axiosInstance()
    .post('/customers/forgot', payload)
    .then((res) => {
      if (res && res.data) {
        return res
      }
    })
    .catch((error) => {
      if (error.response) {
        return error.response
      }
    })
}

const setNewPassword = (payload) => {
  return Api.axiosInitialize()
    .post('/customers/verify', payload)
    .then((res) => {
      if (res && res.data) {
        return res.data
      }
    })
    .catch((error) => {
      if (error.response) {
        return error.response
      }
    })
}

const resetPassword = (payload) => {
  return Api.axiosInitialize()
    .post('/customers/reset', payload)
    .then((res) => {
      if (res && res.data) {
        return res.data
      }
    })
    .catch((error) => {
      if (error.response) {
        return error.response
      }
    })
}

export { forgotPassword, setNewPassword, resetPassword }
