const getAsanaProjectId = ({ projectId }) => {
  const projects = JSON.parse(sessionStorage.getItem('projects'))
  const selectedProject = projects.find(
    (project) => project.id === JSON.parse(projectId)
  )
  const asanaId = selectedProject && selectedProject.asanaId
  return asanaId
}

export default getAsanaProjectId
