import React, { useState, useEffect, useContext, useRef } from 'react'
import classnames from 'classnames'
import {
  Card,
  CardHeader,
  CardBody,
  FormGroup,
  Form,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Container,
  Row,
  Col,
  Button,
} from 'reactstrap'
import Swal from 'sweetalert2'
import NotificationAlert from 'react-notification-alert'
import InputMask from 'react-input-mask'

import { getUser, updateUser } from '../data/user'
import UserContext from '../components/Context/UserContext'

const UserForm = (props) => {
  const notificationRef = useRef()
  const [focusState, setFocusState] = useState({
    firstName: false,
    lastName: false,
    email: false,
    phone: false,
  })
  const [inputState, setInputState] = useState({
    firstName: '',
    lastName: '',
    email: '',
    phone: '',
  })
  const [emailValid, setEmailValid] = useState(true)
  const [phoneValid, setPhoneValid] = useState(true)
  const [nameValid, setNameValid] = useState({
    firstName: true,
    lastName: true,
  })

  const { setUserName } = useContext(UserContext)

  useEffect(() => {
    getUser().then((data) => {
      if (data) {
        const { firstname, lastname, phone, email } = data
        setInputState({
          firstName: firstname,
          lastName: lastname,
          phone,
          email,
        })
      }
    })
  }, [])

  const emailVerify = (value) =>
    /.+@.+\.[A-Za-z]+$/.test(value ? value : inputState.email)
  const phoneVerify = (value) =>
    (value ? value : inputState.phone).replace(/[( \-)]/g, '').length === 10
  const nameVerify = (value) => value.length > 0

  const handleChange = (e) => {
    const { name, value } = e.target
    setInputState({ ...inputState, [name]: value })
    if (name === 'email') {
      setEmailValid(emailVerify(value))
    } else if (name === 'phone') {
      setPhoneValid(phoneVerify(value))
    } else {
      setNameValid({ ...nameValid, [name]: nameVerify(value) })
    }
  }

  const notify = () => {
    let options = {
      place: 'tc',
      message: (
        <div className='alert-text'>
          <span className='alert-title' data-notify='title'>
            Update Complete
          </span>
        </div>
      ),
      type: 'success',
      icon: 'ni ni-bell-55',
      autoDismiss: 3,
    }
    notificationRef.current.notificationAlert(options)
  }

  const handleSubmit = (e) => {
    e.preventDefault()
    const { firstName, lastName, phone, email } = inputState
    const payload = {
      firstname: firstName,
      lastname: lastName,
      phone,
      email,
    }
    updateUser(payload).then((data) => {
      if (data.id) {
        const { firstname, lastname, phone, email } = data
        sessionStorage.setItem('userName', `${firstname} ${lastname}`)
        setUserName(`${firstname} ${lastname}`)
        setInputState({
          firstName: firstname,
          lastName: lastname,
          phone,
          email,
        })
        notify()
      } else {
        const error = data.data && data.data.error
        if (error && error.length > 0) {
          Swal.fire('Oops...', error.join('. '), 'error')
        }
      }
    })
  }

  const disableButton = () => {
    if (!emailValid || !phoneValid) return true
    for (let key in inputState) {
      if (inputState[key] === '') return true
    }
    return false
  }
  return (
    <Container className='mt--3' fluid>
      <div className='rna-wrapper'>
        <NotificationAlert ref={notificationRef} />
      </div>
      <Row style={{ display: 'flex', justifyContent: 'center' }}>
        <Col lg='6'>
          <div className='card-wrapper'>
            <Card>
              <CardHeader>
                <h3 className='mb-0'>Edit Your Information</h3>
              </CardHeader>
              <CardBody>
                <Form
                  style={{ textAlign: 'center' }}
                  className='needs-validation'
                  noValidate
                >
                  <Row style={{ textAlign: 'left' }}>
                    <Col md='6'>
                      <FormGroup>
                        <label
                          className='form-control-label'
                          htmlFor='firstNameInput'
                        >
                          First Name
                        </label>
                        <InputGroup
                          className={classnames('input-group-merge', {
                            focused: !nameValid.firstName
                              ? false
                              : focusState.firstName,
                            error: !nameValid.firstName,
                          })}
                          id='firstNameInput'
                        >
                          <InputGroupAddon addonType='prepend'>
                            <InputGroupText>
                              <i className='fas fa-user' />
                            </InputGroupText>
                          </InputGroupAddon>
                          <Input
                            placeholder='First Name'
                            type='text'
                            name='firstName'
                            value={inputState.firstName}
                            onChange={handleChange}
                            onFocus={() =>
                              setFocusState({ ...focusState, firstName: true })
                            }
                            onBlur={() =>
                              setFocusState({ ...focusState, firstName: false })
                            }
                          />
                        </InputGroup>
                        {!nameValid.firstName && (
                          <div
                            style={{
                              width: '100%',
                              marginTop: '0.25rem',
                              fontSize: '80%',
                              color: '#fb6340',
                            }}
                          >
                            Please enter your first name.
                          </div>
                        )}
                      </FormGroup>
                    </Col>
                    <Col md='6'>
                      <FormGroup>
                        <label
                          className='form-control-label'
                          htmlFor='lastNameInput'
                        >
                          Last Name
                        </label>
                        <InputGroup
                          className={classnames('input-group-merge', {
                            focused: !nameValid.lastName
                              ? false
                              : focusState.lastName,
                            error: !nameValid.lastName,
                          })}
                          id='lastNameInput'
                        >
                          <InputGroupAddon addonType='prepend'>
                            <InputGroupText>
                              <i className='fas fa-user' />
                            </InputGroupText>
                          </InputGroupAddon>
                          <Input
                            placeholder='Last Name'
                            type='text'
                            name='lastName'
                            value={inputState.lastName}
                            onChange={handleChange}
                            onFocus={() =>
                              setFocusState({ ...focusState, lastName: true })
                            }
                            onBlur={() =>
                              setFocusState({ ...focusState, lastName: false })
                            }
                          />
                        </InputGroup>
                        {!nameValid.lastName && (
                          <div
                            style={{
                              width: '100%',
                              marginTop: '0.25rem',
                              fontSize: '80%',
                              color: '#fb6340',
                            }}
                          >
                            Please enter your last name.
                          </div>
                        )}
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row style={{ textAlign: 'left' }}>
                    <Col md='6'>
                      <FormGroup>
                        <label
                          className='form-control-label'
                          htmlFor='emailInput'
                        >
                          Email
                        </label>
                        <InputGroup
                          className={classnames('input-group-merge', {
                            focused: !emailValid ? false : focusState.email,
                            error: !emailValid,
                          })}
                          id='emailInput'
                        >
                          <InputGroupAddon addonType='prepend'>
                            <InputGroupText>
                              <i className='fas fa-envelope' />
                            </InputGroupText>
                          </InputGroupAddon>
                          <Input
                            placeholder='Email address'
                            type='email'
                            name='email'
                            value={inputState.email}
                            onChange={handleChange}
                            onFocus={() => {
                              setEmailValid(emailVerify())
                              setFocusState({ ...focusState, email: true })
                            }}
                            onBlur={() => {
                              setEmailValid(emailVerify())
                              setFocusState({ ...focusState, email: false })
                            }}
                          />
                        </InputGroup>
                        {!emailValid && (
                          <div
                            style={{
                              width: '100%',
                              marginTop: '0.25rem',
                              fontSize: '80%',
                              color: '#fb6340',
                            }}
                          >
                            Please enter a valid email.
                          </div>
                        )}
                      </FormGroup>
                    </Col>
                    <Col md='6'>
                      <label
                        className='form-control-label'
                        htmlFor='phoneInput'
                      >
                        Phone Number
                      </label>
                      <FormGroup>
                        <InputGroup
                          className={classnames('input-group-merge', {
                            focused: !phoneValid ? false : focusState.phone,
                            error: !phoneValid,
                          })}
                          id='phoneInput'
                        >
                          <InputGroupAddon addonType='prepend'>
                            <InputGroupText>
                              <i className='fas fa-phone' />
                            </InputGroupText>
                          </InputGroupAddon>
                          <Input
                            placeholder='Phone number'
                            type='tel'
                            mask='(999) 999 - 9999'
                            maskChar=' '
                            tag={InputMask}
                            name='phone'
                            value={inputState.phone}
                            onChange={handleChange}
                            onFocus={() =>
                              setFocusState({ ...focusState, phone: true })
                            }
                            onBlur={() => {
                              setInputState({
                                ...inputState,
                                phone: inputState.phone.replace(/[( \-)]/g, ''), //regex to remove phone formatting
                              })
                              setFocusState({ ...focusState, phone: false })
                            }}
                          />
                        </InputGroup>
                        {!phoneValid && (
                          <div
                            style={{
                              width: '100%',
                              marginTop: '0.25rem',
                              fontSize: '80%',
                              color: '#fb6340',
                            }}
                          >
                            Please enter a valid phone number.
                          </div>
                        )}
                      </FormGroup>
                    </Col>
                  </Row>
                  <Button
                    color='success'
                    type='button'
                    disabled={disableButton()}
                    onClick={handleSubmit}
                  >
                    Submit Changes
                  </Button>
                </Form>
              </CardBody>
            </Card>
          </div>
        </Col>
      </Row>
    </Container>
  )
}

export default UserForm
