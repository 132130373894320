import React from 'react'
import { Card, CardHeader, CardBody } from 'reactstrap'
import moment from 'moment'

export const TodoAttachments = ({ attachments }) => {
  const attachmentMapper = attachments.map((file, i) => {
    return (
      <div
        key={i}
        style={{
          display: 'flex',
          flexDirection: 'column',
          marginBottom: '0.75rem',
        }}
      >
        <div>
          <small className='text-muted font-weight-bold mr-2'>-</small>
          <a href={file.url} target='_blank' rel='noopener noreferrer'>
            {file.filename}
          </a>
        </div>
        <small
          className='text-muted font-weight-bold'
          style={{ paddingLeft: '0.75rem' }}
        >
          {moment(file.created_at, 'YYYY-MM-33 HH:mm:ss').format(
            'M/DD/YYYY [at] h:mm A'
          )}
        </small>
      </div>
    )
  })
  return (
    <Card>
      <CardHeader className='bg-transparent'>
        <h3 className='mb-0'>Attachments</h3>
      </CardHeader>
      <CardBody>
        {attachments && attachments.length > 0
          ? attachmentMapper
          : 'There are currently no attachments for this to do'}
      </CardBody>
    </Card>
  )
}
