import moment from 'moment'

const momentCustomization = () => {
  const daysInMonth = moment().daysInMonth()
  moment.relativeTimeThreshold('d', daysInMonth)
  moment.relativeTimeThreshold('h', 24)
  moment.relativeTimeThreshold('m', 60)
  moment.relativeTimeThreshold('s', 60)
}

const momentDateCreator = (date) => {
  if (date) {
    let isMoment = moment.isMoment(date)
    return isMoment ? date : moment(date).set({ h: 17, m: 0 })
  }
  return moment().add(1, 'weeks')
}

const isLate = (date) => {
  const momentDate = momentDateCreator(date)
  return moment().isAfter(momentDate) ? true : false
}

const momentCountdown = (date) => {
  const momentDate = momentDateCreator(date)
  const countdownTimer = momentDate.from(moment())

  return countdownTimer
}

export { momentCustomization, momentCountdown, isLate, momentDateCreator }
