import React from 'react'
import { CardTitle, Card, CardBody, Row } from 'reactstrap'

const StatusBarCard = (props) => {
  const { status } = props

  const statusColor = () => {
    if (status.color === 'green') {
      return 'rgb(91,234,206)'
    }
    if (status.color === 'yellow') {
      return 'rgb(255,199,82)'
    }
    if (status.color === 'red') {
      return 'rgb(255,110,118)'
    }
    return 'rgb(0,0,0)'
  }

  const statusTitle = () => {
    if (status.color === 'green') {
      return 'On Track'
    }
    if (status.color === 'yellow') {
      return 'At Risk'
    }
    if (status.color === 'red') {
      return 'Off Track'
    }
    return 'No Status Available'
  }

  return (
    <>
      <CardTitle className='mt-4 h2'>Project Status</CardTitle>
      <Card style={{ height: '50%', backgroundColor: statusColor() }}>
        <CardBody>
          <div>
            <div className='h1 text-white'>{statusTitle()}</div>
          </div>
          <Row>
            <div className='col'>
              <span className='h6 surtitle text-white'>Details</span>
              <span className='d-block h3 text-white'>{status.text}</span>
            </div>
          </Row>
        </CardBody>
      </Card>
    </>
  )
}

export default StatusBarCard
