import React, { useCallback, useState } from 'react'
import { Line } from 'react-chartjs-2'
import moment from 'moment'

const BurnDown = ({ startDate, tasks }) => {
  const [dimensions, setDimensions] = useState({
    left: 0,
    right: 0,
    top: 0,
    bottom: 0,
  })

  const measuredRef = useCallback((node) => {
    if (node !== null) {
      setTimeout(() => {
        const { width, height } = node.chartInstance.canvas
        setDimensions({ ...dimensions, right: width, bottom: height })
      }, 250)
    }
  }, [])

  const dateRange = () => {
    const firstDate = moment(startDate).startOf('week')
    const lastDate = moment(new Date()).startOf('week')
    const totalWeeks = Math.abs(firstDate.diff(lastDate, 'weeks'))

    let dates = []

    for (let i = 0; i <= totalWeeks; i++) {
      dates.push(moment(firstDate).add(i, 'weeks'))
    }

    dates.push(moment(Date.now()))

    return dates
  }

  const formatOpenData = (openTasks) => {
    const dates = dateRange()

    const points = dates.map((date) => {
      return {
        t: moment(date).format('MMM D'),
        y: openTasks.filter(
          (task) =>
            moment(task.completed_at).isAfter(date) || !task.completed_at
        ).length,
      }
    })

    return points
  }

  const formatCompletedData = (completedTasks) => {
    const dates = dateRange()

    const points = dates.map((date) => {
      return {
        t: moment(date).format('MMM D'),
        y: completedTasks.filter(
          (task) => moment(task.completed_at).isBefore(date) && task.completed
        ).length,
      }
    })

    return points
  }

  const chartData = (canvas) => {
    const { left, right, top, bottom } = dimensions
    const paddingOffset = 24
    const x1 = left + paddingOffset
    const x2 = right - paddingOffset
    const yAvg = (bottom + top) / 2
    const ctx = canvas.getContext('2d')
    const gradientBlue = ctx.createLinearGradient(x1, yAvg, x2, yAvg)
    gradientBlue.addColorStop(0, '#1652B0')
    gradientBlue.addColorStop(1, '#01CFF6')
    const gradientOrange = ctx.createLinearGradient(x1, yAvg, x2, yAvg)
    gradientOrange.addColorStop(0, '#F97A7D')
    gradientOrange.addColorStop(1, '#FDB269')
    return {
      labels: dateRange().map((date) => date.format('MMM D')),
      datasets: [
        {
          label: 'Completed User Stories',
          data: formatCompletedData(tasks),
          borderColor: gradientBlue,
          borderWidth: 2,
          backgroundColor: gradientBlue,
          pointHoverBackgroundColor: gradientBlue,
          pointHoverRadius: 7,
          fill: false,
          pointRadius: 0,
        },
        {
          label: 'Open User Stories',
          data: formatOpenData(tasks),
          borderColor: gradientOrange,
          borderWidth: 2,
          backgroundColor: gradientOrange,
          pointHoverBackgroundColor: gradientOrange,
          pointHoverRadius: 7,
          fill: false,
          pointRadius: 0,
        },
      ],
    }
  }

  return <Line data={chartData} options={chartOptions} ref={measuredRef} />
}

const chartOptions = {
  legend: {
    display: true,
    labels: {
      fontColor: '#FFFFFF',
    },
  },
  responsive: true,
  maintainAspectRatio: false,
  scales: {
    xAxes: [
      {
        ticks: {
          fontColor: '#FFFFFF',
        },
        scaleLabel: {
          fontColor: '#FFFFFF',
          fontSize: 16,
        },
        gridLines: {
          color: '#525f7f',
          zeroLineColor: '#525f7f',
        },
      },
    ],
    yAxes: [
      {
        ticks: {
          fontColor: '#FFFFFF',
        },
        scaleLabel: {
          fontColor: '#FFFFFF',
          fontSize: 16,
        },
        gridlines: {
          drawBorder: false,
          color: '#525f7f',
          zeroLineColor: '#525f7f',
        },
      },
    ],
  },
}

export default BurnDown
