import axios from 'axios'

const Api = {
  asanaInstance: () => {
    const instance = axios.create({
      baseURL: process.env.REACT_APP_ASANA_BASE_URL,
      timeout: 20000,
      headers: {
        Authorization: `Bearer ${process.env.REACT_APP_ASANA_BEARER_TOKEN}`,
      },
    })

    return instance
  },
}

export default Api
