/*!

=========================================================
* Argon Dashboard PRO React - v1.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-pro-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from 'react'
// nodejs library that concatenates classes
import classnames from 'classnames'
// nodejs library to set properties for components
import PropTypes from 'prop-types'
// reactstrap components
import { Collapse, Navbar, NavItem, Nav, Container } from 'reactstrap'

import SimpleHeader from '../Headers/SimpleHeader'
import UserDropdown from '../Headers/UserDropdown'

const AdminNavbar = (props) => {
  return (
    <>
      <Navbar
        className={classnames(
          'navbar-top navbar-expand border-bottom',
          { 'navbar-dark helium-gradient': props.theme === 'dark' },
          { 'navbar-light bg-secondary': props.theme === 'light' }
        )}
      >
        <Container fluid>
          <Collapse navbar isOpen={true}>
            <div style={{ flex: 1 }}>
              <SimpleHeader
                name="Your To Do's"
                parentName='Project 1'
                {...props}
              />
            </div>
            <Nav className='align-items-center ml-md-auto' navbar>
              <NavItem className='d-xl-none'>
                <div
                  className={classnames(
                    'pr-3 sidenav-toggler',
                    { active: props.sidenavOpen },
                    { 'sidenav-toggler-dark': props.theme === 'dark' }
                  )}
                  onClick={props.toggleSidenav}
                >
                  <div className='sidenav-toggler-inner'>
                    <i className='sidenav-toggler-line' />
                    <i className='sidenav-toggler-line' />
                    <i className='sidenav-toggler-line' />
                  </div>
                </div>
              </NavItem>
            </Nav>
            <UserDropdown {...props} />
          </Collapse>
        </Container>
      </Navbar>
    </>
  )
}

AdminNavbar.defaultProps = {
  toggleSidenav: () => {},
  sidenavOpen: false,
  theme: 'dark',
}
AdminNavbar.propTypes = {
  toggleSidenav: PropTypes.func,
  sidenavOpen: PropTypes.bool,
  theme: PropTypes.oneOf(['dark', 'light']),
}

export default AdminNavbar
