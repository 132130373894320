import React, { useState, useEffect, useRef, useContext } from 'react'
import { Container } from 'reactstrap'
import { momentCustomization } from '../utils/momentFunctions'
import { TodoList } from '../components/Todos/TodosList'
import { getTodos } from '../data/todos'
import Loader from '../components/Loader'
import { todosSeparator, newContent } from '../utils/todosFunctions'
import UserContext from '../components/Context/UserContext'

const Todos = (props) => {
  momentCustomization()
  const { projectId } = props.match.params
  const isMounted = useRef(true)
  const [todos, setTodos] = useState([])
  const [isLoaded, setIsLoaded] = useState(false)
  const { setTaskUpdated } = useContext(UserContext)

  useEffect(() => {
    return () => (isMounted.current = false)
  }, [])

  useEffect(() => {
    setIsLoaded(false)
    fetchData()
  }, [projectId])

  const fetchData = () => {
    getTodos(projectId).then((data) => {
      if (isMounted.current) {
        setTodos(data)
        setIsLoaded(true)
        for (let i = 0; i < data.length; i++) {
          if (newContent(data[i])) {
            setTaskUpdated({ project_id: data[i].project_id, newContent: true })
            break
          }
          if (i === data.length - 1) {
            setTaskUpdated({
              project_id: data[i].project_id,
              newContent: false,
            })
          }
        }
      }
    })
  }

  const { lateTodos, upComingTodos, closedTodos } = todosSeparator(todos)

  if (!isLoaded) {
    return <Loader />
  }
  return (
    <>
      <Container className='mt-3' fluid>
        {lateTodos.length > 0 && (
          <div style={{ marginBottom: '1rem' }}>
            <TodoList
              data={lateTodos}
              title={'Overdue'}
              late={true}
              defaultCollapsed={true}
              icon='ni ni-watch-time'
            />
          </div>
        )}
        {upComingTodos.length > 0 && (
          <div style={{ marginBottom: '1rem' }}>
            <TodoList
              data={upComingTodos}
              title={'Up Coming'}
              late={false}
              defaultCollapsed={true}
              icon='ni ni-calendar-grid-58'
            />
          </div>
        )}
        <TodoList
          data={closedTodos}
          title={'Closed'}
          late={false}
          closed={true}
          defaultCollapsed={false}
          icon='ni ni-check-bold'
        />
      </Container>
    </>
  )
}

export default Todos
