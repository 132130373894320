import React from 'react'
import { Card, CardHeader, CardBody } from 'reactstrap'
import moment from 'moment'
import {
  blockType,
  blockIcon,
  blockColor,
  blockContext,
} from '../../utils/todosFunctions'

export const TodoHistory = ({ history }) => {
  const historyBlock =
    history &&
    history.map((block, i) => {
      return (
        <div
          className={`timeline-block ${
            block.userType === 'user' ? 'block-float-right' : ''
          }`}
          key={i}
        >
          <span className={`timeline-step badge-${blockColor(block.type)}`}>
            <i className={`ni ni-${blockIcon(block.type)}`} />
          </span>
          <div className='timeline-content'>
            <small className='text-muted font-weight-bold'>
              {moment(block.date, 'YYYY-MM-DD hh:mm:ss').format(
                'MM/DD/YYYY [at] h:mm A'
              )}
              {' - '}
              {block.user}
            </small>
            <h5 className='mt-3 mb-0'>{blockType(block.type)}</h5>
            {blockContext(block)}
          </div>
        </div>
      )
    })
  return (
    <Card>
      <CardHeader className='bg-transparent'>
        <h3 className='mb-0'>History</h3>
      </CardHeader>
      <CardBody>
        <div
          className='timeline'
          data-timeline-axis-style='dashed'
          data-timeline-content='axis'
        >
          {historyBlock}
        </div>
      </CardBody>
    </Card>
  )
}
